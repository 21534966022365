import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ReactGA from 'react-ga';
import Modal from 'react-modal';
import './index.css';

Modal.setAppElement('#root');

ReactGA.initialize('G-CFB1VC54X1');

ReactDOM.render(<App />, document.getElementById('root'));
